var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vx-form',{attrs:{"resource":_vm.formResource},on:{"submit":_vm.handleSubmit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
return [_c('vx-select-resource',{attrs:{"resource":_vm.typesResource,"label":"Type","name":"question_content_id"},model:{value:(_vm.formValue.typeId),callback:function ($$v) {_vm.$set(_vm.formValue, "typeId", $$v)},expression:"formValue.typeId"}}),_c('vx-select',{attrs:{"options":_vm.formValue.contents,"rules":_vm.rules.question_content_id,"reduce":function (ref) {
	var id = ref.id;

	return id;
},"options-label":"title","label":"Question","name":"question_content_id"},model:{value:(_vm.formValue.question_content_id),callback:function ($$v) {_vm.$set(_vm.formValue, "question_content_id", $$v)},expression:"formValue.question_content_id"}}),_c('vx-select',{attrs:{"options":_vm.formValue.contents,"rules":_vm.rules.answer_content_id,"reduce":function (ref) {
	var id = ref.id;

	return id;
},"options-label":"title","label":"Answer","name":"answer_content_id"},model:{value:(_vm.formValue.answer_content_id),callback:function ($$v) {_vm.$set(_vm.formValue, "answer_content_id", $$v)},expression:"formValue.answer_content_id"}}),_c('vx-checkbox',{attrs:{"name":"is_active","label":"Active"},model:{value:(_vm.formValue.is_active),callback:function ($$v) {_vm.$set(_vm.formValue, "is_active", $$v)},expression:"formValue.is_active"}}),_c('vx-button',{attrs:{"loading":loading,"variant":"primary","type":"submit"}},[_vm._v(" Save ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <vx-page-title-actions>
      <vx-button
        :can="canCreate"
        variant="primary"
        @click="openModal()"
      >
        Add
      </vx-button>
      <span v-if="isDragged" class="ml-1">
        <vx-button
          class="mr-1"
          variant="success"
          :loading="loading"
          @click="updatePositions"
        >
          Apply
        </vx-button>
        <vx-button
          variant="danger"
          @click="cancelPositions"
        >
          Discard
        </vx-button>
      </span>
    </vx-page-title-actions>
    <vx-table
      ref="tableRef"
      :resource="resource"
      :columns="columns"
      :data-mapper="itemsSort"
      sortable
      :reset-sortable-cache="resetSortableCache"
      @sortableCallback="onDragged"
    />
    <b-modal
      v-model="modalIsOpen"
      :title="modalTitle"
      hide-footer
      title-tag="h4"
      @close="closeModal"
    >
      <FAQ-editor :entity="entity" :last-position="lastPosition" @submit="handleSubmit" />
    </b-modal>
  </div>
</template>

<script>
import {
  VxCellButtons,
  VxCellDate,
  VxTable,
  VxCellDraggable
} from '@/components/table'
import VxPageTitleActions from '@/components/layout/VxPageTitleActions'
import { VxButton } from '@/components/buttons'
import { faq, passDataToResource, useResource } from '@/services/resources'
import { permissions, useCan } from '@/plugins/acl'
import { useModalEditor } from '@/services/modal'
import { useDraggable } from '@/services/draggable'
import { computed, ref, watch } from '@vue/composition-api'
import { buttons } from '@/components/table/vx-table-cells/vx-cell-button'
import { BModal } from 'bootstrap-vue'
import FAQEditor from '@/views/texts/FAQEditor'
import { orderBy } from 'lodash'

export default {
  name: 'FAQ',
  components: {
    VxTable,
    VxPageTitleActions,
    VxButton,
    VxCellDraggable,
    VxCellButtons,
    FAQEditor,
    BModal
  },
  setup () {
    const tableRef = ref(null)
    const lastPosition = ref(0)

    const resource = useResource(faq.getAll)

    const { modalIsOpen, entity, openModal, closeModal } = useModalEditor()
    const {
      isDragged,
      resetSortableCache,
      loading,
      onDragged,
      cancelPositions,
      updatePositions
    } = useDraggable(tableRef, faq.updatePositions)

    const canCreate = useCan(permissions.faqCreate)
    const canEdit = useCan(permissions.faqUpdate)

    const actionButtons = ({ id, is_active, question_content_id, answer_content_id, position }) => {
      const turnOff = { ...buttons.turnOff, label: 'Enabled', can: canEdit }
      const turnOn = { ...buttons.turnOn, label: 'Disabled', can: canEdit }

      const statusButton = {
        ...(is_active ? turnOff : turnOn),
        resource: passDataToResource(faq.update, {
          requestParams: {
            urlParams: { id },
            data: {
              is_active: !is_active,
              question_content_id,
              answer_content_id,
              position
            }
          }
        })
      }

      const editButton = {
        ...buttons.edit,
        can: canEdit,
        onClick: openModal
      }

      const deleteButton = {
        ...buttons.delete,
        resource: passDataToResource(faq.delete, {
          requestParams: { urlParams: { id } }
        })
      }

      return [statusButton, editButton, deleteButton]
    }

    const modalTitle = computed(() => {
      return (entity.value ? 'Edit' : 'Create') + ' FAQ'
    })

    const handleSubmit = () => {
      closeModal()
      tableRef.value.refresh()
    }

    const itemsSort = (items) => orderBy(items, 'position', 'asc')

    watch(() => tableRef.value?.items, () => {
      if (tableRef.value?.items.length) {
        lastPosition.value = Math.max(...tableRef.value?.items.map(i => i.position))
      }
    })

    const columns = [
      { key: 'position', component: VxCellDraggable },
      { key: 'id' },
      { key: 'question' },
      { key: 'answer' },
      {
        key: 'actions',
        component: VxCellButtons,
        tdAttr: (value, key, item) => ({
          buttons: actionButtons(item)
        })
      },
      { key: 'created_at', component: VxCellDate },
      { key: 'updated_at', component: VxCellDate }
    ]

    return {
      tableRef,
      resource,
      columns,
      itemsSort,
      canCreate,

      entity,
      lastPosition,
      handleSubmit,

      modalTitle,
      modalIsOpen,
      openModal,
      closeModal,

      onDragged,
      isDragged,
      loading,
      cancelPositions,
      updatePositions,
      resetSortableCache
    }
  }
}
</script>

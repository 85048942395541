<template>
  <vx-form
    v-slot="{ loading }"
    :resource="formResource"
    @submit="handleSubmit"
  >
    <vx-select-resource
      v-model="formValue.typeId"
      :resource="typesResource"
      label="Type"
      name="question_content_id"
    />

    <vx-select
      v-model="formValue.question_content_id"
      :options="formValue.contents"
      :rules="rules.question_content_id"
      :reduce="({ id }) => id"
      options-label="title"
      label="Question"
      name="question_content_id"
    />

    <vx-select
      v-model="formValue.answer_content_id"
      :options="formValue.contents"
      :rules="rules.answer_content_id"
      :reduce="({ id }) => id"
      options-label="title"
      label="Answer"
      name="answer_content_id"
    />

    <vx-checkbox
      v-model="formValue.is_active"
      name="is_active"
      label="Active"
    />

    <vx-button
      :loading="loading"
      variant="primary"
      type="submit"
    >
      Save
    </vx-button>
  </vx-form>
</template>

<script>
import { VxButton } from '@/components/buttons'
import { VxForm, VxSelectResource, VxSelect, VxCheckbox } from '@/components/form'
import { contents, faq, passDataToResource, useResource } from '@/services/resources'
import { reactive, watch } from '@vue/composition-api'
import { setValuesToForm } from '@/services/form'

export default {
  name: 'FAQEditor',
  components: { VxForm, VxSelectResource, VxSelect, VxCheckbox, VxButton },
  props: {
    entity: {
      type: Object,
      default: null
    },
    lastPosition: {
      type: Number,
      default: 0
    }
  },
  setup ({ entity, lastPosition }, { emit }) {
    const isEdit = !!entity

    const contentsResource = async (id) => {
      const params = { filter: { type: id }, per_page: 100 }
      const { can, callRequest } = useResource(contents.getAll)

      if (can) {
        const [, res] = await callRequest({ params })

        return res
      }
    }

    const setTypeIdByContent = async (id) => {
      const payload = {
        urlParams: { id }
      }
      const { can, callRequest } = useResource(contents.getOne)

      if (can) {
        const [, res] = await callRequest(payload)
        formValue.typeId = res.data.type
      }
    }

    const frontToBackMapper = ({ question_content_id, answer_content_id, is_active }) => {
      return {
        question_content_id,
        answer_content_id,
        is_active,
        position: isEdit ? entity.position : lastPosition + 1
      }
    }

    const typesResource = contents.getTypes

    const formResource = isEdit
      ? passDataToResource(faq.update, {
        frontToBackMapper,
        requestParams: { urlParams: { id: entity.id } }
      })
      : passDataToResource(faq.create, {
        frontToBackMapper
      })

    const formValue = reactive({
      question_content_id: null,
      answer_content_id: null,
      is_active: false,

      typeId: null,
      contents: []
    })

    const rules = {
      question_content_id: {
        required: true
      },
      answer_content_id: {
        required: true
      }
    }

    watch(() => formValue.typeId, () => {
      if (formValue.typeId) {
        contentsResource(formValue.typeId).then(res => {
          formValue.contents = res.data
        })
      }
    })

    const handleSubmit = ([, res]) => {
      if (res) {
        emit('submit', res)
      }
    }

    if (isEdit) {
      setTypeIdByContent(entity.question_content_id)
      setValuesToForm(entity, formValue)
    }

    return {
      typesResource,
      formResource,
      formValue,
      rules,
      handleSubmit
    }
  }
}
</script>
